import React, {useEffect} from 'react';
import { Text, View, StyleSheet, ScrollView } from 'react-native';
import Header from './../Header.js';


function Privacy() {



    return (
        <View style={{flex:1}}>
            
            <View style={styles.MainContainer}>
                <ScrollView>
                    <Text style={styles.TitleStyle}>Informativa sul trattamento dei dati personali</Text>
                    <Text style={styles.TextStyle}>Con la presente informativa, resa ai sensi dell’art. 13 del Regolamento (UE) 2016/679 (di seguito “Regolamento”), Telisoft services S.r.l.s., con sede legale in Viale Gorgia di Leontini 330, Roma (di seguito “Telisoft”), in qualità di Titolare del trattamento, desidera illustrarti le finalità e le modalità con cui raccoglie e tratta i tuoi dati personali e quali categorie di dati personali sono oggetto di trattamento, quali sono i diritti degli interessati al trattamento e come possono essere esercitati.</Text>
                    <Text style={styles.TitleStyle}>1. Categorie di dati personali</Text>
                    <Text style={styles.TextStyle}>Telisoft tratterà i dati personali che ci fornirai al momento della conclusione del contratto e nel corso del rapporto contrattuale. In particolare, Telisoft acquisirà:</Text>
                    <Text style={styles.TextStyle}>i) i tuoi dati identificativi (nome e cognome), il tuo indirizzo, ed eventualmente anche i tuoi recapiti (indirizzo di posta elettronica), il tuo sesso e la data di nascita;</Text>
                    <Text style={styles.TextStyle}>ii) informazioni connesse all’utilizzo dell'app' (es. transazioni effettuate, volumi di acquisto, tipologia di beni acquistati, frequenza di consumo).</Text>
                    <Text style={styles.TextStyle}>Il conferimento dei dati personali indicati è necessario per l’erogazione dei servizi richiesti; in difetto, ci sarà impossibile fornirti detti servizi.</Text>
                    <Text style={styles.TitleStyle}>2. Finalità e base giuridica del trattamento</Text>
                    <Text style={styles.TitleStyle}>a. Per l’esecuzione del servizio</Text>
                    <Text style={styles.TextStyle}>I tuoi dati personali saranno trattati da Telisoft l’erogazione dei relativi servizi. In particolare, i tuoi dati personali saranno trattati per lo svolgimento di attività operative e l’utilizzo dell'app. Per detta finalità, la Telisoft tratterà tutti i dati personali indicati al punto i) del paragrafo 1.</Text>
                    <Text style={styles.TitleStyle}>c. Per finalità di marketing</Text>
                    <Text style={styles.TextStyle}>c. 1 Telisoft potrà trattare i tuoi dati personali per l’invio di comunicazioni di natura commerciale e/o per iniziative promozionali ai fini dell’offerta diretta di propri prodotti e/o servizi analoghi a quelli da te già sottoscritti.
                        Telisoft svolgerà tale attività nel rispetto dei principi del GDPR e per il perseguimento di un proprio legittimo interesse; in ogni caso, potrai opporti in qualunque momento alla ricezione di tali comunicazioni scrivendo a Telisoft all’indirizzo email info@telisoft.it.
                        c.2 Fatto salvo quanto previsto nel precedente paragrafo c.1, con il tuo espresso e specifico consenso, Telisoft potrà inoltre trattare i tuoi dati personali per iniziative promozionali (presenti e future) di natura commerciale, invio di materiale pubblicitario, compimento di ricerche di mercato, vendita diretta, comunicazione commerciale, relative a tutta la gamma dei prodotti e/o servizi offerti da Telisoft, da esercizi convenzionati e partner terzi. L’invio delle comunicazioni commerciali potrà avvenire mediante l’utilizzo di sistemi tradizionali (posta cartacea, chiamate con operatore) e di sistemi di comunicazione automatizzati (es. SMS, e-mail, chiamate senza operatore, notifiche sull’App).
                        Per il perseguimento delle finalità specificate nei paragrafi c.1 e c.2, Telisoft potrà trattare i dati personali di cui al punto i) del paragrafo 1; inoltre, qualora tu abbia prestato il tuo consenso allo svolgimento di attività di profilazione di cui alla lettera d) che segue, Telisoft potrà trattare per fini di marketing anche le informazioni indicate al punto ii) del paragrafo 1.
                    </Text>
                    <Text style={styles.TitleStyle}>d. Per finalità di profilazione</Text>
                    <Text style={styles.TextStyle}>Previo tuo espresso e specifico consenso, Telisoft potrà trattare i tuoi dati personali per meglio comprendere le tue abitudini ed interessi e, di conseguenza, offrirti prodotti e servizi che riteniamo ti possano essere graditi. Per il perseguimento di tale finalità, Telisoft potrà trattare i dati personali di cui ai punti i) e ii) del paragrafo 1.
                        Il consenso da te prestato potrà essere revocato in qualsiasi momento scrivendo a info@telisoft.it.
                    </Text>
                    <Text style={styles.TitleStyle}>e. Per la comunicazione dei tuoi dati a terzi per fini di marketing</Text>
                    <Text style={styles.TextStyle}>Previo tuo espresso e specifico consenso, Telisoft potrà comunicare alcuni tuoi dati personali a società operanti nello stesso settore merceologico del Titolare. Tali entità potrebbero usare i tuoi dati personali per finalità commerciali e promozionali, avvalendosi sia di sistemi automatizzati (es. SMS, e-mail, notifiche sull’applicazione) che di canali tradizionali (posta cartacea).
                        Potranno essere oggetto di comunicazione i tuoi dati identificativi, il tuo indirizzo e i tuoi recapiti (indirizzo di posta elettronica).
                        Il consenso da te prestato potrà essere revocato in qualsiasi momento, scrivendo a info@telisof.it.
                    </Text>
                    <Text style={styles.TitleStyle}>3. Categorie di soggetti ai quali i dati personali possono essere comunicati e finalità della comunicazione</Text>
                    <Text style={styles.TextStyle}>Telisoft potrà comunicare alcuni tuoi dati personali a soggetti terzi dei quali si avvale per lo svolgimento di attività connesse alla erogazione dei servizi oggetto del contratto. In particolare, i tuoi dati personali potranno essere comunicati a società esterne che offrono servizi amministrativi e logistici, servizi informatici, contabili e di consulenza. I suddetti soggetti potranno trattare i dati in qualità di responsabili per conto di Telisoft o di titolari autonomi nel rispetto delle disposizioni di legge.
                        L’elenco dei Responsabili del trattamento può essere richiesto scrivendo a info@telisof.it.
                        Telisoft, inoltre, potrà comunicare i tuoi dati personali a propri partner commerciali nell’ambito di specifiche iniziative. Tali soggetti tratteranno i tuoi dati personali in qualità di autonomi titolari del trattamento.
                        Non è prevista alcuna forma di diffusione dei tuoi dati personali a soggetti indeterminati.
                    </Text>
                    <Text style={styles.TitleStyle}>4. Periodo di conservazione dei dati</Text>
                    <Text style={styles.TextStyle}>I tuoi dati personali saranno trattati solo per il tempo necessario per le finalità sopra menzionate.
                        In particolare riportiamo qui di seguito i principali periodi di utilizzo e conservazione dei tuoi dati personali con riferimento alle diverse finalità di trattamento:
                        1. per finalità connesse all’esecuzione del servizio, i tuoi dati personali saranno trattati da Telisoft per tutta la durata del servizio e fino a che sussistano obbligazioni o adempimenti connessi all’esecuzione dello stesso e saranno conservati per un periodo di 11 anni successivo a tale termine esclusivamente per finalità connesse all’adempimento di obblighi di legge e per consentire ad Telisoft la difesa dei propri diritti;
                        2. per l’adempimento di obblighi di legge, i tuoi dati personali saranno trattati e conservati da Telisoft finché persista la necessità del trattamento per adempiere a detti obblighi di legge;
                        3. con riferimento ai trattamenti per fini di marketing, i tuoi dati personali saranno trattati per tutta la durata del servizio relativo all'app e fino a che sussistano obbligazioni o adempimenti connessi all’esecuzione di tale servizio, salvo eventuale revoca del consenso da te prestato;
                        4. per finalità di profilazione, i tuoi dati personali saranno trattati per un periodo massimo di 12 mesi o per il diverso periodo che dovesse essere disposto dalla legge o da provvedimenti dell’Autorità garante, decorsi i quali gli stessi saranno conservati se necessari per perseguire altre finalità o saranno definitivamente cancellati;
                    </Text>
                    <Text style={styles.TitleStyle}>5. Trasferimento dei dati fuori dall’Unione europea</Text>
                    <Text style={styles.TextStyle}>Non è previsto il trasferimento dei tuoi dati personali al di fuori dell’Unione europea.</Text>
                    <Text style={styles.TitleStyle}>6. Diritti degli interessati</Text>
                    <Text style={styles.TextStyle}>Ti informiamo che, in conformità alla vigente disciplina, hai i seguenti diritti:
                        • chiedere e ottenere informazioni in merito all’esistenza di tuoi dati personali presso Telisoft e in merito ai trattamenti di dati personali posti in essere dalla stessa, nonché ottenere l’accesso agli stessi;
                        • chiedere ed ottenere la ricezione in un formato strutturato, di uso comune e leggibile da dispositivo automatico dei tuoi dati personali che siano trattati con mezzi automatizzati; potrai richiedere, inoltre, il trasferimento degli stessi ad altro titolare del trattamento;
                        • chiedere e ottenere la modifica e/o correzione dei tuoi dati personali;
                        • chiedere e ottenere la cancellazione – e/o la limitazione del trattamento - dei tuoi dati personali qualora si tratti di dati o informazioni non necessari – o non più necessari – per le finalità che precedono, quindi decorso il periodo di conservazione indicato al paragrafo che precede.
                        Tali richieste potranno essere indirizzate ad Telisoft scrivendo alla mail info@telisof.it. Ti informiamo che qualsiasi richiesta formulata nei termini che precedono sarà intesa da Telisoft come riferita esclusivamente ai dati personali detenuti con riferimento all'app. Qualora, nell’ambito di altri programmi o di altri rapporti contrattuali in essere con Telisoft, tu abbia fornito i tuoi dati personali, la richiesta da te formulata con riferimento all'app' non si riferirà ai dati personali forniti nell'ambito di tali altri rapporti contrattuali.
                        Ti informiamo, infine, che ai sensi della disciplina vigente puoi proporre eventuali reclami riguardanti i trattamenti di tuoi dati personali al Garante per la protezione dei dati personali.
                    </Text>
                </ScrollView>
            </View>     
        </View>   
    );
}

const styles = StyleSheet.create({
 
    MainContainer :{
        justifyContent: 'center',
        flex:1,
        margin: 10,
        alignItems:'center',
    },
    TextStyle: {
        color: 'black',
        fontFamily:'OpenSans-Regular'
    },
    TitleStyle: {
        color: 'black',
        fontWeight:'bold',
        marginTop:5,
        marginBottom:5
    }
});

export default Privacy;