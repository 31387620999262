import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import {View, Text, Image } from 'react-native';

function Footer() {
  return (
    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Azienda</h2>
            <Link to='https://www.telisoft.it/chi-siamo/'>Chi siamo</Link>
            <Link to='https://www.superspesaonline.it/Privacy/'>Privacy</Link>

          </div>
          <div class='footer-link-items'>
            <h2>Software</h2>
            <Link to='https://www.telisoft.it/super/'>Super</Link>
            <View style={{marginTop:20}}>
              <Link to='https://www.telisoft.it/cash/'>Cash</Link>
            </View>
            <View style={{marginTop:20}}>
              <Link to='https://www.telisoft.it/diso/'>Diso</Link>
            </View>
            <View style={{marginTop:20}}>
              <Link to='https://www.telisoft.it/superprice/'>Superprice</Link>
            </View>
            <View style={{marginTop:20}}>
              <Link to='https://www.telisoft.it/servizi/'>App GdoOrdini</Link>
            </View>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Contatti</h2>
            <View style={{flexDirection:'row',justifyContent:'center'}}>
              <Image 
                style={{width: 25, height: 25, marginRight:10}}
                source={require('./../images/address.png')}
                resizeMode="contain" 
              />
              <Link to='/'>Viale Gorgia di Leontini 330, Roma, Italia</Link>
            </View>
            <View style={{flexDirection:'row',justifyContent:'center'}}>
              <Image 
                style={{width: 25, height: 25, marginRight:10}}
                source={require('./../images/phone.png')}
                resizeMode="contain" 
              />
              <Link to='/'>(+39) 348-6986296</Link>
            </View>
            <View style={{flexDirection:'row',justifyContent:'center',marginTop:20}}>
              <Image 
                style={{width: 25, height: 25, marginRight:10}}
                source={require('./../images/email2.png')}
                resizeMode="contain" 
              />
              <Link to='/'>info@telisoft.it</Link>
            </View>
            <View style={{flexDirection:'row',justifyContent:'center',marginTop:20}}>
              <Image 
                style={{width: 25, height: 25, marginRight:10}}
                source={require('./../images/time2.png')}
                resizeMode="contain" 
              />
              <Link to='/'>Lun - Ven 8:00 - 20:00 Sab 8:00 - 13:00</Link>
            </View>
          </div>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <View style={{flexDirection:'row',justifyContent:'center'}}>
              <Link to='https://www.instagram.com/telisoft/'>
                <Image 
                  style={{width: 25, height: 25, marginRight:10}}
                  source={require('./../images/instagram.png')}
                  resizeMode="contain" 
                />
              </Link>
              <Link to='https://www.facebook.com/telisoftsrl/'>
                <Image 
                  style={{width: 25, height: 25, marginRight:10}}
                  source={require('./../images/facebook.png')}
                  resizeMode="contain" 
                />
              </Link>
              <Link to='https://www.linkedin.com/company/telisoftsrl/'>
                <Image 
                  style={{width: 25, height: 25, marginRight:10}}
                  source={require('./../images/linkedin.png')}
                  resizeMode="contain" 
                />
              </Link>
              <Link to='https://www.youtube.com/@telisoft9167'>
                <Image 
                  style={{width: 25, height: 25, marginRight:10}}
                  source={require('./../images/youtube.png')}
                  resizeMode="contain" 
                />
              </Link>
            </View>
          </div>
        </div>
      </div>
      <View style={{flexDirection:'row',justifyContent:'center',marginTop:10}}>
        <Link to='https://www.telisoft.it/'>
          <Image 
            style={{width: 30, height: 30, marginRight:10}}
            source={require('./../images/logo.png')}
            resizeMode="contain" 
          />
        </Link>
        <Text style={{color:'white'}}>Telisoft services srls © 2023</Text>
      </View>
    </div>
  );
}

export default Footer;