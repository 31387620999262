import * as React from 'react';
import {View, Text, TouchableOpacity, Image, Dimensions, ActivityIndicator, StyleSheet } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default class  TotaleSpesa extends React.Component { 
  constructor(props) {
    super(props);
    this.state = { 
      isLoading: true,
    }; 
  }
  componentDidMount = async () => {
    try {
      const storedValue = await AsyncStorage.getItem('puntovendita');
      this.setState({ puntovendita: storedValue }); 
    } catch (error) {
      alert("Error retrieving data" + error)
    }
    
    var puntovendita = this.state.puntovendita;  
    var totalespesa = this.state.totalespesa;
    var SearchAPIURL="https://gdoordini.it/superspesa/php/totaleordini.php";
    
    var header={
      'Accept':'application/json',
      'Content-Type':'application/json'
    };
    
    var Data={
        puntovendita:puntovendita,
        totalespesa:totalespesa
    };
    fetch(
      (SearchAPIURL),
      {
        method:'POST',
        headers:header,
        body: JSON.stringify(Data)
      }
    )
    .then(response => response.json())
    .then(response => { 
      
        this.setState({isLoading: false});
        this.setState({totalespesa: response[0].totalespesa});
    })
    .catch(error => { ;
    });
  } 
  render() {
    const {  isLoading  } = this.state;
    const windowWidth = Dimensions.get('window').width;
   
    return (
      <View style={{flexDirection:'row', flex:1, marginLeft:10,marginRight:10, marginTop:10,}}>
        {isLoading ? (
          <View style={{flex: 1,justifyContent:'center'}}>
            <ActivityIndicator size="large" color="#333c5e" />
          </View>
        ) : (
          <View style={{flexDirection:'row', flex:1, marginLeft:10,marginRight:10, marginTop:10}} >
            <View style={{flex:0.7}}>
              <View style={{    flexDirection:'column',flex:1}}  >
                <View style={{flex:0.2}}>
                  <Text style={{color:'#c8cfe3', fontWeight:'bold'}}>ORDINI</Text>
                </View>
                <View style={windowWidth < 768 ? styles.ViewTotaleSmall : styles.ViewTotale}>
                  <Text style={windowWidth < 768 ? styles.TotaleSmall : styles.Totale}>{this.state.totalespesa}</Text>
                </View>
                <View style={windowWidth < 768 ? styles.ViewMeseSmall : styles.ViewMese}>
                  <View style={{flexDirection:'row'}}>
                    <Text style={{color:'grey', fontWeight:'bold'}}>{this.state.totalecurrentMonth}</Text>
                    <Text style={{color:'#c8cfe3',  marginLeft:10}}>dall'ultimo mese</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{flex:0.3, alignItems:'flex-end',marginRight:10}}>
              <Image 
                style={{height:50,width:50}}
                source={require('./../images/shopping-bag.png')} />
            </View>
          </View>
        )}
      </View>
    )
  }
}

const styles = StyleSheet.create({
  Totale: {
    color:'#333c5e', 
    fontWeight:'bold',
    fontSize:40  
  },
  TotaleSmall: {
    color:'#333c5e', 
    fontWeight:'bold',
    fontSize:30  
  },
  ViewTotale: {
    flex:0.6
  },
  ViewTotaleSmall: {
    flex:0.6,
    marginTop:10
  },
  ViewMese: {
    flex:0.2
  },
  ViewMeseSmall: {
    flex:0.2,
    marginBottom:10
  }
})