import React , { useEffect, useState } from "react";
import ReactDOM from "react-dom";





const PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
function Paypal({ orderAmountFromOtherApp }) {
  const [orderAmount, setOrderAmount] = useState(orderAmountFromOtherApp);
  


    function _createOrder(data, actions) {
      return actions.order.create({
        purchase_units: [
          {
            amount: {
              value: orderAmount , // Utilizza orderAmount se è definito, altrimenti usa 0.1
            },
          },
        ],
      });
    }

  





  async function _onApprove(data, actions) {
    let order = await actions.order.capture();
    console.log(order);
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify(order));
    return order;
  }
  function _onError(err) {
    console.log(err);
    let errObj = {
      err: err,
      status: "FAILED",
    };
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify(errObj));
  }
  
  return (
    <div className="App">
      <PayPalButton
        createOrder={(data, actions) => _createOrder(data, actions)}
        onApprove={(data, actions) => _onApprove(data, actions)}
        onCancel={() => _onError("CANCELED")}
        onError={(err) => _onError("ERROE")}
      />
      
    </div>
  );
}
export default Paypal;