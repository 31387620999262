
import '../App.css';
import React, { Component } from "react";
import { StyleSheet, Text, View, Image, TextInput, TouchableOpacity, Dimensions } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';

export default class HeroSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      email:'',
      password:''
    };
  }

  SearchAccount=async()=> {
       
    var email=this.state.email;
    var password=this.state.password;
    var puntovendita=this.state.puntovendita;
    var codice=this.state.codice;
       
    if (email==null && password==null) {
      alert("Utente e Password non inserite");
    }
    else if(email==null) {
      alert("Utente non inserito");
    }
    else if(password==null) {
      alert("Password non inserita");
    }
    else {
      var SearchAPIURL="https://gdoordini.it/superspesa/php/logincheck.php";

      var header={
        'Accept':'application/json',
        'Content-Type':'application/json'
      };

      var Data={
        email:email,
        password:password,
        puntovendita:puntovendita,
        codice:codice
      };
      fetch(
        SearchAPIURL,
        {
          method:'POST',
          headers:header,
          body: JSON.stringify(Data)
        }
      )
      .then((response)=>response.json())
      .then((response)=> 
        {
          var email=response[0].email;
          var password=response[0].password;
          var puntovendita=response[0].puntovendita;
          var id=response[0].id;

          if (email != null && password != null && puntovendita != null && id != null) {

            var controlpuntovendita = response[0].puntovendita;
            if (controlpuntovendita === 'GranRisparmioPetacciato') {
              AsyncStorage.setItem('codice', '1234');
            }
                
            AsyncStorage.setItem('email', email);
            AsyncStorage.setItem('password', password);                         
            AsyncStorage.setItem('puntovendita', response[0].puntovendita);
            AsyncStorage.setItem('id', response[0].id);
            window.location.href = "/Dashboard";
          } else {
            alert('Utente non presente')
          }
        }
      )
      .catch((error)=>
        {
          alert("Error4"+ error);
        }
      )
    }
  }
 
  render() {

    const windowWidth = Dimensions.get('window').width;
    return (
      <View style={{flexDirection:'row',flex:1}}>
        {windowWidth > 768 ?  (
        <View style={styles.container2}>
          <img   src="/images/superspesaweb.png" />
        </View>
        ) : (
          <View />
        )}
        <View style={windowWidth < 768 ? styles.containerSmall : styles.container} >
          <View style={styles.ViewTitle}>
            <Image 
              source={require('./../images/SuperSpesa.png')}
              resizeMode="contain"
              style={styles.Image}
               
            /> 
            <View style={styles.ViewTextTitle}>
              <Text style={styles.TextTitle}>SUPERSPESA</Text>
              <Text style={styles.TextSubTitle}>LA SPESA IN UN CLICK</Text>
            </View>
          </View>
          <View style={windowWidth < 768 ? styles.inputViewSmall : styles.inputView}>
            <TextInput
              style={styles.TextInput}
              placeholder="Email"
              placeholderTextColor="grey"
              onChangeText={email => this.setState({email})}
              onFocus={(e) => {
                e.target.style.outline = 'none'; 
              }}
            />
          </View>
          <View style={windowWidth < 768 ? styles.inputViewSmall : styles.inputView}>
            <TextInput
              style={styles.TextInput}
              placeholder="Password"
              placeholderTextColor="grey"
              secureTextEntry={true}
              onChangeText={password => this.setState({password})}
              onFocus={(e) => {
                e.target.style.outline = 'none'; 
              }}
            />
          </View>
          <TouchableOpacity style={windowWidth < 768 ? styles.loginBtnSmall : styles.loginBtn} onPress={this.SearchAccount}>
            <Text style={styles.loginText}>LOGIN</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}
 
const styles = StyleSheet.create({
  container: {
    flex: 0.4,
    backgroundColor: "#f4f7ff",
    alignItems: "center",
    justifyContent: "center",
  },
  containerSmall: {
    flex: 1,
    backgroundColor: "#f4f7ff",
    alignItems: "center",
    justifyContent: "center",
    marginTop:10
  },
  container2: {
    flex: 0.6,   
    alignItems: "center",
    justifyContent: "center",
    borderRightWidth:10,
    borderRightColor:'black'
  }, 

  inputView: {
    borderRadius: 30,
    textAlign:'center',
    height: 45,
    marginBottom: 20,
    width: "50%",
    alignItems: "center",
    borderWidth:1,
    borderColor:'#333c5e'
  },
  inputViewSmall: {
    borderRadius: 30,
    textAlign:'center',
    height: 45,
    marginBottom: 20,
    width: "75%",
    alignItems: "center",
    borderWidth:1,
    borderColor:'#333c5e'
  },
  TextInput: {
    height: 50,
    flex: 1,
    padding: 10,
    marginLeft: 20,
    textAlign:'center',
   
  },
  loginBtn: {
    width: "50%",
    borderRadius: 25,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    backgroundColor: "#333c5e",
  },
  loginBtnSmall: {
    width: "75%",
    borderRadius: 25,
    height: 50,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 40,
    backgroundColor: "#333c5e",
    marginBottom:10
  },
  loginText: {
    color:'white'
  },
  ViewTitle: {
    flexDirection:'row', 
    alignItems:'center', 
    justifyContent:'center', 
    marginBottom:50, 
    
  },
  ViewTextTitle: {
    flexDirection:'column',
    alignItems:'center'
  },
  TextTitle: {
    color:'orangered', 
    fontSize:40, 
    fontFamily:'Verdana',
    fontWeight:'bold'
    
  },
  TextSubTitle: {
    color:'grey', 
    fontSize:15, 
    fontFamily:'Verdana',
    fontWeight:'bold'
  },
  Image: {
    height:75, 
    width:75,
    marginRight:10
  },

});