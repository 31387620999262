import * as React from 'react';
import { View, TouchableOpacity, Text, Dimensions, ScrollView, StyleSheet } from 'react-native';
import Sidebar from './../components/Sidebar';
import Header from './../Header.js';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ChartTotaliRicavi from './../components/ChartTotaliRicavi';
import LineTotaliOrdini from './../components/LineTotaliOrdini';
import TotaleRicavi from './../components/TotaleRicavi';
import TotaleSpesa from './../components/TotaleSpesa';
import TotaleClienti from './../components/TotaleClienti';
import AggiornaArticoli from './../components/AggiornaArticoli';
import AggiornaArticoliOfferta from './../components/AggiornaArticoliOfferta';
import moment from 'moment';
import 'moment/locale/it';


const DifferenzaOrdiniMese = async () => {
  try {
    const storedValue = await AsyncStorage.getItem('puntovendita');
    this.setState({ puntovendita: storedValue }); 
  } catch (error) {
    alert("Error retrieving data" + error)
  }

  var puntovendita = this.state.puntovendita;  
  var currentMonth = moment().subtract(0, 'months');
  var currentMonthFormat = currentMonth.format('MM')
  var currentYearFormat = currentMonth.format('YYYY')
  var totaleOrdinicurrentMonth = this.state.totaleOrdinicurrentMonth;

  var previousMonth = moment().subtract(1, 'months');
  var previousMonthFormat = previousMonth.format('MM')
  var previousYearFormat = previousMonth.format('YYYY')
  var totaleOrdinipreviousMonth = this.state.totaleOrdinipreviousMonth;

  var SearchAPIURL="https://gdoordini.it/superspesa/php/differenzaordinimese.php";

  var header={
    'Accept':'application/json',
    'Content-Type':'application/json'
  };

  var Data={
    currentMonthFormat:currentMonthFormat,
    currentYearFormat:currentYearFormat,
    totaleOrdinicurrentMonth:totaleOrdinicurrentMonth,
    previousMonthFormat:previousMonthFormat,
    previousYearFormat:previousYearFormat,
    totaleOrdinipreviousMonth:totaleOrdinipreviousMonth
  };
  fetch(
    (SearchAPIURL),
    {
      method:'POST',
      headers:header,
      body: JSON.stringify(Data)
    }
  )
  .then(response => response.json())
  .then(response => { 
    this.setState({totaleOrdinicurrentMonth: response[0].totaleOrdinicurrentMonth});
    this.setState({totaleOrdinipreviousMonth: response[0].totaleOrdinipreviousMonth});
  })
  .catch(error => { alert(error)
  });
} 

const DifferenzaClientiMese = async () => {
  try {
    const storedValue = await AsyncStorage.getItem('puntovendita');
    this.setState({ puntovendita: storedValue }); 
  } catch (error) {
    alert("Error retrieving data" + error)
  }

  var puntovendita = this.state.puntovendita;  
  var currentMonth = moment().subtract(0, 'months');
  var currentMonthFormat = currentMonth.format('MM')
  var currentYearFormat = currentMonth.format('YYYY')
  var ClienticurrentMonth = this.state.ClienticurrentMonth;

  var previousMonth = moment().subtract(1, 'months');
  var previousMonthFormat = previousMonth.format('MM')
  var previousYearFormat = previousMonth.format('YYYY')
  var ClientipreviousMonth = this.state.ClientipreviousMonth;


  var SearchAPIURL="https://gdoordini.it/superspesa/php/differenzaordinimese.php";

  var header={
    'Accept':'application/json',
    'Content-Type':'application/json'
  };

  var Data={
    currentMonthFormat:currentMonthFormat,
    currentYearFormat:currentYearFormat,
    ClienticurrentMonth:ClienticurrentMonth,
    previousMonthFormat:previousMonthFormat,
    previousYearFormat:previousYearFormat,
    ClientipreviousMonth:ClientipreviousMonth
  };
  fetch(
    (SearchAPIURL),
    {
      method:'POST',
      headers:header,
      body: JSON.stringify(Data)
    }
  )
  .then(response => response.json())
  .then(response => { 
    this.setState({ClienticurrentMonth: response[0].ClienticurrentMonth});
    this.setState({ClientipreviousMonth: response[0].ClientipreviousMonth});
  })
  .catch(error => { alert(error)
  });
} 



const Dashboard = () => {

  const windowWidth = Dimensions.get('window').width;
  return (
    <View style={{backgroundColor:'#f4f7ff'}}>
      <Header />
      <Sidebar>
        
        <View style={windowWidth < 768 ? styles.ViewTotaliSmall : styles.ViewTotali}>
          <View style={windowWidth < 768 ? styles.ViewRicaviSmall : styles.ViewRicavi}>
            <TotaleRicavi />
          </View>
          <View style={windowWidth < 768 ? styles.ViewTotaleSpesaSmall : styles.ViewTotaleSpesa}>
            <TotaleSpesa />
          </View>
          <View style={windowWidth < 768 ? styles.ViewTotaleSpesaSmall : styles.ViewTotaleSpesa}>
            <TotaleClienti />
          </View>  
        </View>
        <View style={windowWidth < 768 ? styles.ViewGraficiSmall : styles.ViewGrafici}>
          <View style={windowWidth < 768 ? styles.ViewChartSmall : styles.ViewChart}>
            <ChartTotaliRicavi />
          </View>
          <View style={windowWidth < 768 ? styles.ViewLineSmall : styles.ViewLine}>
            <LineTotaliOrdini />
          </View>
        </View>
        <View style={windowWidth < 768 ? styles.ViewAggiornaSmall : styles.ViewAggiorna}>
          <AggiornaArticoli />
          <AggiornaArticoliOfferta />
        </View>   
         
      </Sidebar>
    </View>
  );
};

export default Dashboard;

const styles = StyleSheet.create({
  ViewTotali: {
    flexDirection:'row', 
    flex:1, 
    marginLeft:10,
    marginRight:10, 
   
    justifyContent:'center',
    alignItems:'center', 
    marginTop:25  
  },
  ViewTotaliSmall: {
    flexDirection:'column', 
    flex:1, 
    justifyContent:'center',
    alignItems:'center', 
    marginTop:25,
    marginLeft:10,
    
  },
  ViewTotaleSpesa: {
    flex:0.33,
    borderRadius:10, 
    backgroundColor:'white',
    height:200, 
    marginLeft:20
  },
  ViewTotaleSpesaSmall: {
    flex:0.33,
    borderRadius:10, 
    backgroundColor:'white',
    height:200, 
    
    marginTop:10,
    width:'90%',

  },
  
  ViewRicavi: {
    flex:0.33,
    borderRadius:10, 
    backgroundColor:'white',
    height:200
  },
  ViewRicaviSmall: {
    flex:0.33,
    borderRadius:10, 
    backgroundColor:'white',
    height:'100%',
    width:'90%',
  },
  ViewGrafici: {
    flexDirection:'row', 
    flex:1, 
    marginLeft:10,
    marginRight:10, 
    marginTop:25,
    justifyContent:'center',
    alignItems:'center'
  },
  ViewGraficiSmall: {
    flexDirection:'column', 
    flex:1, 
    marginLeft:10,
    marginRight:10,
    marginTop:25,
    justifyContent:'center',
    alignItems:'center'
  },
  ViewAggiorna: {
    flexDirection:'row',
    flex:1,
    marginTop:50
  },
  ViewAggiornaSmall: {
    flexDirection:'column',
    flex:1,
    marginTop:50,
    
  },
  ViewLine: {
    flex:0.5,
    marginLeft:10,
    backgroundColor:'white', 
    borderRadius:10
  },
  ViewLineSmall: {
    flex:0.5,
    width:'100%',
    backgroundColor:'white', 
    borderRadius:10,
    marginTop:20
    
  },
  ViewChart: {
    flex:0.5,
    backgroundColor:'white', 
    borderRadius:10
  },
  ViewChartSmall: {
    flex:0.5,
    backgroundColor:'white', 
    borderRadius:10,
    width:'100%',
    marginTop:10
  },
})