import * as React from 'react';
import {View, Text, TouchableOpacity, Image, ActivityIndicator, Alert, ScrollView, StyleSheet, TextInput, Dimensions} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Header from './../Header.js';
import Sidebar from './../components/Sidebar';
import {Picker} from '@react-native-picker/picker';

export default class  Profilo extends React.Component { 
    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
        }; 
    }
    componentDidMount=()=> {
        this.SearchPuntovendita();
    }


    SearchPuntovendita=async()=> {
        try {
            const storedValue = await AsyncStorage.getItem('puntovendita');
            this.setState({ nomePuntoVendita: storedValue });
            
        } catch (error) {
            Alert.alert("Error retrieving data" + error)
        }
        
        var puntovendita=this.state.nomePuntoVendita;       
  
        var SearchAPIURL="https://gdoordini.it/superspesa/php/searchprofilo.php";
  
        var header={
            'Accept':'application/json',
            'Content-Type':'application/json'
        };
  
        var Data={
            puntovendita:puntovendita,

        };
  
        fetch(
            (SearchAPIURL),
            {
                method:'POST',
                headers:header,
                body: JSON.stringify(Data)
            }
        )
              
        .then((response)=>response.json())
        .then((response)=>
        {
            
            this.setState({nome:response[0].nome});
            this.setState({cognome:response[0].cognome});
            this.setState({email:response[0].email});
            this.setState({password:response[0].password});
            this.setState({datanascita:response[0].datanascita});
            this.setState({sesso:response[0].sesso});
            this.setState({puntovendita:response[0].puntovendita});
          
            this.setState({ isLoading: false });
           
        })
        .catch((error)=>
        {
            alert(error)
        })
    }

    handleNomeChange = (nome) => {
        this.setState({ nome });
    };

    handleCognomeChange = (cognome) => {
        this.setState({ cognome });
    };

    handleEmailChange = (email) => {
        this.setState({ email });
    };
    handlePasswordChange = (password) => {
        this.setState({ password });
    };
    
    handleDataNascitaChange = (datanascita) => {
        this.setState({ datanascita });
    };

    handleSessoChange = (sesso) => {
        this.setState({ sesso });
    };


    salvaPuntoVendita =async () => {

        var email=this.state.email;
        var checkemail=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    
        if (checkemail.test(email) === true) {

            var nome=this.state.nome;
            var cognome=this.state.cognome;    
            var password=this.state.password;
            var datanascita=this.state.datanascita;       
            var sesso=this.state.sesso;
            var puntovendita=this.state.puntovendita;
            
            
           

            if (nome.length == 0 || cognome.length == 0 || email.length == 0 || password.length == 0 || datanascita.length == 0 || sesso.length == 0 || puntovendita.length == 0)
            {
                alert("Inserire tutti i campi");
            }

            else
            {

                var SearchAPIURL="https://gdoordini.it/superspesa/php/salvaprofilo.php";

                var header={
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                };

                var Data={
                    nome:nome,
                    cognome:cognome,
                    email:email,
                    password:password,
                    datanascita:datanascita,
                    sesso:sesso,
                    puntovendita:puntovendita
                };
            
                fetch(
                    SearchAPIURL,
                    {
                        method:'POST',
                        headers:header,
                        body: JSON.stringify(Data)
                    }
                )
                .then((response)=>response.json())
                .then((response)=> {

                    alert('Successo', 'Modifiche salvate', [
                        {

                        },
                        {text: 'OK', },
                    ]);
                })
                .catch((error)=>
                    {
    
                        alert("Error4"+ error);
          
                    }
                )
            }
        } else {
        
            Alert.alert('Errore', 'Inserisci una email valida!');
        }
    };


    render() {

        const { nome, cognome, email, password, datanascita, sesso, puntovendita, isLoading  } = this.state;
        const windowWidth = Dimensions.get('window').width;
        
        return (
            <View style={{backgroundColor:'#f4f7ff'}}>
                <View>
                    <Header />
                    <Sidebar>
                        <ScrollView contentContainerStyle={{flex:1,alignItems:'center'}}>
                            {isLoading ? (
                                <View style={{flex: 1,justifyContent:'center'}}>
                                    <ActivityIndicator size="large" color="#333c5e" />
                                </View>
                            ) : (
                                <View style={windowWidth < 768 ? styles.ViewStyleSmall : styles.viewStyle}>
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/user.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>
                                            <TextInput
                                                placeholder="Nome*"
                                                value={this.state.nome}
                                                onChangeText={this.handleNomeChange}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />
                                        </View>
                                    </View> 
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/user.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>
                                            <TextInput
                                                placeholder="Cognome"
                                                value={this.state.cognome}
                                                onChangeText={this.handleCognomeChange}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />
                                        </View>
                                    </View>
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/email.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>
                                            <TextInput
                                                placeholder="Email"
                                                value={this.state.email}
                                                onChangeText={this.handleEmailChange}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />
                                        </View>
                                    </View>
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/padlock.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>
                                            <TextInput
                                                placeholder="Password"
                                                value={this.state.password}
                                                onChangeText={this.handlePasswordChange}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />
                                        </View>
                                    </View>                                    
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/calendar.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>
                                            <TextInput
                                                placeholder="Data di nascita"
                                                value={this.state.datanascita}
                                                onChangeText={this.handleDataNascitaChange}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />
                                        </View>
                                    </View>
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/sex.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>
                                            <Picker
                                                selectedValue={sesso}
                                                onValueChange={this.handleSessoChange}
                                                style={{backgroundColor:'white',height:50, fontSize:20}}
                                            >
                                                <Picker.Item label="Genere*" value="" color="gray"/>
                                                <Picker.Item label="Uomo" value="uomo" color="black"/>
                                                <Picker.Item label="Donna" value="donna" color="black"/>
                                            </Picker> 
                                        </View>
                                    </View>
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/store.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>                                                        
                                            <TextInput
                                                placeholder="Punto Vendita"
                                                value={this.state.puntovendita}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />  
                                        </View>
                                    </View>
                                    <View style={{marginBottom:10,marginTop:50,marginLeft:10,marginRight:10,alignItems:'center'}}>
                                        <TouchableOpacity style={styles.RegistrazioneStyle} onPress={this.salvaPuntoVendita}>
                                            <Text style={{ color:'white', fontFamily:'LilitaOne-Regular'}}>SALVA</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            )
                            }
                        </ScrollView>
                    </Sidebar >
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    TextInputStyleClass: {
        fontSize:20,
        marginBottom: 5,
        height: 50,
        borderBottomWidth: 1,
        borderColor: 'grey',
        fontFamily:'OpenSans-Regular',
    },
    TextInputStyleClassSmall: {
        fontSize:15,
        marginBottom: 5,
        height: 50,
        borderBottomWidth: 1,
        borderColor: 'grey',
        fontFamily:'OpenSans-Regular',
        
    },
    RegistrazioneStyle :{
        width:'25%',
        height:50,
        backgroundColor:'#333c5e',
        alignItems:'center',
        justifyContent:'center',
        borderRadius:10,
        marginBottom:5
    },
    viewStyle: {
        justifyContent: 'center', 
        backgroundColor:'white',
        width:500,
        borderRadius:10,
    },
    ViewStyleSmall: {
        marginLeft:10, 
        marginRight:10, 
        marginTop:10, 
        marginBottom:10,
        backgroundColor:'white', 
        borderRadius:10,
        width:'90%'
    },
    ImageStyle: {
        width: 50, 
        height: 50
    },
    ImageStyleSmall: {
        width: 25, 
        height: 25
    },
    VievImageStyle: {
        marginRight:10,
        justifyContent:'center',
        flex:0.5
    },
    VievImageStyleSmall: {
        marginRight:10,
        justifyContent:'center',
        flex:0.25
    },  
});