import * as React from 'react'; 
import {Text, View, StyleSheet, FlatList, ActivityIndicator, Alert, TouchableOpacity, Image, TextInput, Dimensions } from 'react-native';
import Sidebar from './../components/Sidebar';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Header from './../Header.js';



export default class Ordini extends React.Component { 
    constructor(props) {
        super(props);
        this.state = { 
            search: '', 
            isLoading: true,
            isSearch: true,
            FindEan:'',
            numero:20
        }; 
       
    }
    
    componentDidMount = async () => {
        this.onLoad(); 
    }

    handleClick = async() => {
        this.setState(prevState => ({
            numero: prevState.numero + 20,
        }));    
        this.setState({isLoading: true});
        this.onLoad();
    };

    onLoad = async () => {
        this.setState({isSearch: true}); 
        try {
            const storedValue = await AsyncStorage.getItem('puntovendita');
            this.setState({ puntovendita: storedValue }); 
        } catch (error) {
            alert("Error retrieving data" + error)
        }
        
        var puntovendita = this.state.puntovendita;  
        var data= this.state.data;
        var totalestoricospesa = this.state.totalestoricospesa;
        var numero = this.state.numero; 
        
        var SearchAPIURL="https://gdoordini.it/superspesa/php/vedostoricoordini.php";
    
        var header={
          'Accept':'application/json',
          'Content-Type':'application/json'
        };
        
        var Data={
            puntovendita:puntovendita,
            data:data,
            totalestoricospesa:totalestoricospesa,
            numero:numero
           
        };
        fetch(
            (SearchAPIURL),
            {
                method:'POST',
                headers:header,
                body: JSON.stringify(Data)
            }
        )
        .then(response => response.json())
        .then(responseJson => { 
            
            this.setState(
                {
                    isLoading: false, dataSource: responseJson,
                },
                function() {
                    this.arrayholder = responseJson;
                }
            );
        })
        .catch(error => { alert(error);});
    }

    handlesearchTextChange = (searchText) => {
        this.setState({ searchText });
    };
    
    handleSearch = () => {
        this.setState({isSearch: false});  
        this.setState({numero: 20}); 
        var searchText = this.state.searchText;
        
       if (searchText != undefined && searchText.length > 0 ) {

            var puntovendita = this.state.puntovendita;  
        
            var SearchAPIURL="https://gdoordini.it/superspesa/php/cercostoricoordini.php";
    
            var header={
                'Accept':'application/json',
                'Content-Type':'application/json'
            };
        
            var Data={
                puntovendita:puntovendita,
                searchText:searchText
            };
            fetch(
                (SearchAPIURL),
                {
                    method:'POST',
                    headers:header,
                    body: JSON.stringify(Data)
                }
            )
            .then(response => response.json())
            .then(responseJson => { 
           
                this.setState(
                    {
                        isLoading: false, dataSource: responseJson,
                    },
                );
            })
            .catch(error => { alert(error);});
        } else {
            this.onLoad();
        }
    };
      
    render() {
        
        const Separator = () => (
            <View style={styles.separator} />
        );
      
        const { isLoading, searchText, isSearch  } = this.state;
        const windowWidth = Dimensions.get('window').width;
        return (
            <View style={{backgroundColor:'#f4f7ff'}}>
                <Header isCart={true}/>
                <Sidebar>
                    <View style={{alignItems:'center',marginLeft:10,marginRight:10}}>
                        {isLoading ? (
                            <View style={{flex: 1,justifyContent:'center'}}>
                                <ActivityIndicator size="large" color="#333c5e" />
                            </View>
                        ) : (
                            <View style={windowWidth < 768 ? styles.viewStyleSmall: styles.viewStyle}>
                                <View style={{flexDirection:'row'}}> 
                                    <View style={{flex:0.1,justifyContent:'center', alignItems:'center',borderLeftWidth:1,borderTopWidth:1,borderBottomWidth:1,borderColor:'grey',borderTopLeftRadius:10,borderBottomLeftRadius:10}}> 
                                        <TouchableOpacity onPress={this.handleSearch}>
                                            <Image 
                                                style={styles.Image}
                                                source={require('./../images/Ricerca.png')}
                                                resizeMode="contain" 
                                            />
                                        </TouchableOpacity>  
                                    </View>
                                    <View style={{flex:0.9}}>    
                                        <TextInput
                                            style={{ height: 50,   borderTopRightRadius:10,borderBottomRightRadius:10,borderRightWidth:1,borderTopWidth:1,borderBottomWidth:1,borderColor:'grey' }}
                                            placeholder="Cerca..."
                                            onChangeText={this.handlesearchTextChange}
                                            value={this.state.searchText}
                                            onFocus={(e) => {
                                                e.target.style.outline = 'none'; 
                                            }}
                                        />
                                    </View>
                                </View>            
                                <Separator />
                                <View style={styles.ViewSub}>
                                    <View style={styles.ViewTitleCodice}>
                                        <Text style={windowWidth < 768 ? styles.TitleSubSmall: styles.TitleSub}>NOME</Text>
                                    </View>
                                    <View style={styles.ViewTitleCodice}>
                                        <Text style={windowWidth < 768 ? styles.TitleSubSmall: styles.TitleSub}>COGNOME</Text>
                                    </View>  
                                    <View style={styles.ViewTitleCodice}>
                                        <Text style={windowWidth < 768 ? styles.TitleSubSmall: styles.TitleSub}>DATA</Text>
                                    </View> 
                                    <View style={styles.ViewTitleCodice}>
                                        <Text style={windowWidth < 768 ? styles.TitleSubSmall: styles.TitleSub}>TOTALE SPESA</Text>
                                    </View> 
                                    <View style={styles.ViewTitleCodice}>
                                        
                                    </View>                                     
                                                                                                    
                                </View>
                                <FlatList 
                                    data={this.state.dataSource}
                                    renderItem={({ item }) => (
                                        <View style={windowWidth < 768 ? styles.viewStyleSmall: styles.viewStyle}>
                                            <View style={styles.ViewSub}>
                                                <View style={styles.ViewTitleCodice}>
                                                    <Text style={windowWidth < 768 ? styles.TitleSub2Small: styles.TitleSub2}>{item.nome}</Text>
                                                </View>
                                                <View style={styles.ViewTitleCodice}>
                                                    <Text style={windowWidth < 768 ? styles.TitleSub2Small: styles.TitleSub2}>{item.cognome}</Text>
                                                </View> 
                                                <View style={styles.ViewTitleCodice}>
                                                    <Text style={windowWidth < 768 ? styles.TitleSub2Small: styles.TitleSub2}>{item.data}</Text>
                                                </View>                                        
                                                <View style={styles.ViewTitleCodice}>
                                                    <Text style={windowWidth < 768 ? styles.TitleSub2Small: styles.TitleSub2}>{item.totalestoricospesa}</Text>
                                                    
                                                </View>
                                                <View style={{flex:0.2, alignItems:'center'}}>
                                                <TouchableOpacity  onPress={() =>  window.location.href ="VediOrdine?idordine=" + item.idordine + "&id=" + item.id}>
                                                    <Image 
                                                        style={{width: 25, height: 25}}
                                                        source={require('./../images/eye.png')}
                                                        resizeMode="contain" 
                                                    />
                                                </TouchableOpacity>    
                                                </View>                                        
                                            </View>                                       
                                        </View>
                                    )}
                                    enableEmptySections={true} 
                                    style={{ marginTop: 11 }}
                                    keyExtractor={(item, index) => index.toString()}
                                />
                            </View>
                        )}
                        {isSearch ? (
                            <TouchableOpacity style={{marginTop:10}} onPress={this.handleClick}>
                                <Text style={{fontWeight:'bold',color:'#333c5e'}}>MOSTRA DI PIU'</Text>
                            </TouchableOpacity>
                        ) : (
                            <View></View>
                        )}
                    </View> 
                </Sidebar>
            </View>
        );
    }
}

const styles = StyleSheet.create({ 
    viewStyle: {
        justifyContent: 'center', 
        backgroundColor:'white',
        width:1000,
        borderRadius:10,
    },
    viewStyleSmall: {
        justifyContent: 'center', 
        backgroundColor:'white',
        width:'100%',
        borderRadius:10
    },   

    ViewTitleCodice: {
        flex:0.2, 
       height:50,
       justifyContent:'center'
    },
    ViewTitleCodice2: {
        flex:0.3,
        height:50,
        justifyContent:'center',
        
    },
    
    ViewSub: {
        flexDirection:'row', 
        justifyContent:'center',
        alignItems:'center'
    },
    TitleSub:{
        fontSize:15, 
        fontWeight:'bold',
        fontFamily:'OpenSans-Regular',
        textAlign:'center',
        color:'#333c5e'
    },
    TitleSubSmall:{
        fontSize:10, 
        fontWeight:'bold',
        fontFamily:'OpenSans-Regular',
        textAlign:'center',
        color:'#333c5e'
    },
    TitleSub2:{
        fontSize:15, 
        color:'grey',
        fontFamily:'OpenSans-Regular',
        textAlign:'center'
    },
    TitleSub2Small:{
        fontSize:10, 
        color:'grey',
        fontFamily:'OpenSans-Regular',
        textAlign:'center'
    },

    Image: {
        width: 25, 
        height: 25
    },
    separator: {
       height: 10,
        backgroundColor:'#f4f7ff'
    },
});