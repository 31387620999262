import './index.css';
import React, { Component } from 'react';
import {StyleSheet,Text,View,Image,TextInput,Button,TouchableOpacity,ActivityIndicator, Dimensions} from "react-native";
import {Picker} from '@react-native-picker/picker';
import AsyncStorage from '@react-native-async-storage/async-storage';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      codfat:'',
      denominazionecessionario:''
    };
  }
    
  Searchdenominazionecessionario=async()=>
  {
    try {
      const value = await AsyncStorage.getItem('email');
      this.setState({codfat: value});
    } catch (error) {
      console.log("Error retrieving data" + error);
    }

    var codfat=this.state.codfat;
    var SearchAPIURL="https://gdoordini.it/superspesa/php/denominazionecessionario.php";
        
    var header={
      'Accept':'application/json',
      'Content-Type':'application/json',
    };
    var Data={
      codfat:codfat,
    };
    fetch(
      (SearchAPIURL),
      {
        method:'POST',
        headers:header,
        body: JSON.stringify(Data)
      }
    )
    .then((response)=>response.json())
    .then((response)=>
      {
        this.setState({nome: response[0].nome});
        this.setState({cognome: response[0].cognome});  
        this.setState({denominazionecessionario: response[0].puntovendita});
        this.setState({ isLoading: false });
      }
    )
    .catch((error)=>
      {
      }
    )
  }

  componentDidMount() {

    this.Searchdenominazionecessionario();

  }

  handleValueChange=(value)=> {
        
    if (value === 'Profilo') {
      window.location.href = "/Profilo";
    } 
    if (value === 'Esci') {
      AsyncStorage.clear();
      window.location.href = "/";
    } else {
         
    }

  }

  render() {
    const windowWidth = Dimensions.get('window').width;
    const { isLoading  } = this.state;
    return (
      <View>
        {isLoading ?
          <View style={{flex: 1,justifyContent:'center'}}>
            <ActivityIndicator size="large" color="#333c5e" />
          </View>
        :
          <View style={{flex:1,backgroundColor:'trasparent',flexDirection:'row'}}>
            <View style={windowWidth < 768 ? styles.ViewDenominazioneConcessionarioSmall : styles.ViewDenominazioneConcessionario}>
              <Text style={windowWidth < 768 ? styles.TextClienteSmall : styles.TextCliente}>{this.state.denominazionecessionario}</Text>
            </View>
            <View style={windowWidth < 768 ? styles.ViewImageSmall : styles.ViewImage}>
              <TouchableOpacity onPress={this.Esci}>
                <Image 
                  style={windowWidth < 768 ? styles.ImageStyleSmall : styles.ImageStyle}
                 
                  source={require('./images/girl.png')} 
                />
              </TouchableOpacity>
              <View style={{flexDirection:'column',marginLeft:10,marginTop:10, alignItems:'flex-start'}}>
                <Picker
                  onValueChange={this.handleValueChange}  
                  style={windowWidth < 768 ? styles.PickerStyleSmall : styles.PickerStyle}                          
                  
                >
                  <Picker.Item label={this.state.nome + ' ' + this.state.cognome} value="" color="#333c5e" />
                  <Picker.Item label="Profilo" value="Profilo" color="#333c5e" />
                  <Picker.Item label="Esci" value="Esci" color="#333c5e" />
                </Picker> 
                <Text style={windowWidth < 768 ? styles.TextStyleSmall : styles.TextStyle}>Amministratore</Text>
              </View>
            </View>  
          </View> 
        }
      </View> 
    );
  }
}

const styles = StyleSheet.create({
  TextCliente: {
    color: '#333c5e',
    justifyContent:'flex-start',
    textAlign:'center',
    fontWeight:'bold',
    fontSize:20,    
  },
  TextClienteSmall: {
    color: '#333c5e',
    justifyContent:'flex-start',
    textAlign:'center',
    fontWeight:'bold',
    fontSize:10,    
  },
  PickerStyle: {
    backgroundColor:'#f4f7ff',
    height:50, 
    fontSize:20,
    color:'#333c5e',
    fontWeight:'bold',
    borderWidth: 0
  },
  PickerStyleSmall: {
    backgroundColor:'#f4f7ff',
    
    fontSize:10,
    color:'#333c5e',
    fontWeight:'bold',
    borderWidth: 0
  },
  TextStyle: {
    fontSize:10, 
    color:'#333c5e'
  },
  TextStyleSmall: {
    fontSize:5, 
    color:'#333c5e'
  },
  ImageStyle: {
    height:50,
    width:50
  },
  ImageStyleSmall: {
    height:25,
    width:25
  },
  ViewDenominazioneConcessionario: {
    flex:0.9,
    justifyContent:'center',
    marginLeft:50, 
    height:50
  },
  ViewDenominazioneConcessionarioSmall: {
    flex:0.5,
    justifyContent:'center',
    marginLeft:10, 
    height:50
  },
  ViewImage:{
    flex:0.1, 
    justifyContent:'flex-end',
    flexDirection:'row',
    marginRight:50,
    alignItems:'center'
  },
  ViewImageSmall:{
    flex:0.5, 
    justifyContent:'flex-end',
    flexDirection:'row',
    marginRight:10,
    alignItems:'center'
  }
})

export default Header;