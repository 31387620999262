import React, { useState } from 'react';
import { FaTh, FaUserAlt, FaStoreAlt, FaShoppingBag, FaAppleAlt }from "react-icons/fa";
import {Text, Image, Dimensions } from 'react-native';
import { NavLink } from 'react-router-dom';


const Sidebar = ({children}) => {
    const windowWidth = Dimensions.get('window').width;
    const[isOpen ,setIsOpen] = useState(true);
    const toggle = () => setIsOpen (!isOpen);
    const menuItem=[
        {
            path:"/dashboard",
            name:"Dashboard",
            icon:<FaTh/>
        },
        {
            path:"/PuntoVendita",
            name:"Punto Vendita",
            icon:<FaStoreAlt/>
        },
        {
            path:"/Clienti",
            name:"Clienti",
            icon:<FaUserAlt/>
        },        
        {
            path:"/ProdottiList",
            name:"Prodotti",
            icon:<FaAppleAlt/>
        },
        {
            path:"/Ordini",
            name:"Ordini",
            icon:<FaShoppingBag/>
        },        

    ]
    return (
        <div className="container">
           <div style={{width: isOpen && windowWidth < 768 ? "50px" : "300px"}} className="sidebar">
               <div className="top_section">
                   <h1 style={{display: isOpen && windowWidth < 768 ? "none" : "block"}} className="logo">
                        <Image 
                            style={{width: 200, height: 50}}
                            source={require('./../images/granrisparmiosupermercati.png')}
                            resizeMode="contain" 
                        />
                   </h1>
                   <div style={{marginLeft: isOpen && windowWidth < 768 ? "0px" : "50px"}} className="bars">
                    </div>
               </div>
               {
                   menuItem.map((item, index)=>(
                       <NavLink to={item.path} key={index} className="link" activeclassName="active">
                           <div className="icon" >{item.icon}</div>
                           <div style={{display: isOpen && windowWidth < 768 ? "none" : "block"}} className="link_text">{item.name}</div>
                       </NavLink>
                   ))
               }
            </div>
            <main>{children}</main>
        </div>
    );
};

export default Sidebar;