import * as React from 'react';
import { Bar } from 'react-chartjs-2';
import {View,  ActivityIndicator, Dimensions} from 'react-native';
import moment from 'moment';
import 'moment/locale/it';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default class ChartTotaliRicavi extends React.Component { 
  
  constructor(props) {
    super(props);
    this.state = { 
      isLoading: true,
    }; 
  } 
    
  componentDidMount = async () => {
    this.TotaleRicaviMese(); 
  }

  TotaleRicaviMese = async () => {
    try {
      const storedValue = await AsyncStorage.getItem('puntovendita');
      this.setState({ puntovendita: storedValue }); 
    } catch (error) {
      alert("Error retrieving data" + error)
    }
    
    var puntovendita = this.state.puntovendita;  
    var sixMonth = moment().subtract(6, 'months');
    var six = sixMonth.format('MM')
    var annosix = sixMonth.format('YYYY')
    var totalesestomese = this.state.totalesestomese;

    var fiveMonth = moment().subtract(5, 'months');
    var five = fiveMonth.format('MM')
    var annofive = fiveMonth.format('YYYY')
    var totalequintomese = this.state.totalequintomese;

    var fourMonth = moment().subtract(4, 'months');
    var four = fourMonth.format('MM')
    var annofour = fourMonth.format('YYYY')
    var totalequartomese = this.state.totalequartomese;

    var threeMonth = moment().subtract(3, 'months');
    var three = threeMonth.format('MM')
    var annothree = threeMonth.format('YYYY')
    var totaleterzomese = this.state.totaleterzomese;

    var twoMonth = moment().subtract(2, 'months');
    var two = twoMonth.format('MM')
    var annotwo = twoMonth.format('YYYY')
    var totalesecondomese = this.state.totalesecondomese;

    var oneMonth = moment().subtract(1, 'months');
    var one = oneMonth.format('MM')
    var annoone = oneMonth.format('YYYY')
    var totaleprimomese = this.state.totaleprimomese;

    var SearchAPIURL="https://gdoordini.it/superspesa/php/totalericavimese.php";
   
    var header={
      'Accept':'application/json',
      'Content-Type':'application/json'
    };
        
    var Data={
      puntovendita:puntovendita,
      six:six,
      annosix:annosix,
      totalesestomese:totalesestomese,
      five:five,
      annofive:annofive,
      totalequintomese:totalequintomese,
      four:four,
      annofour:annofour,
      totalequartomese:totalequartomese,
      three:three,
      annothree:annothree,
      totaleterzomese:totaleterzomese,
      two:two,
      annotwo:annotwo,
      totalesecondomese:totalesecondomese,
      one:one,
      annoone:annoone,
      totaleprimomese:totaleprimomese   
    };
    fetch(
      (SearchAPIURL),
      {
        method:'POST',
        headers:header,
        body: JSON.stringify(Data)
      }
    )
    .then(response => response.json())
    .then(response => { 
      this.setState({isLoading: false});
      this.setState({totalesestomese: response[0].totalesestomese});
      this.setState({totalequintomese: response[0].totalequintomese});
      this.setState({totalequartomese: response[0].totalequartomese});
      this.setState({totaleterzomese: response[0].totaleterzomese});
      this.setState({totalesecondomese: response[0].totalesecondomese});
      this.setState({totaleprimomese: response[0].totaleprimomese});
    })
    .catch(error => { alert(error)
    });
  } 

  render() {
        
    const currentDate = moment(); 
    const sixMonthsAgo = moment().subtract(6, 'months'); 
    const fiveMonthsAgo = moment().subtract(5, 'months'); 
    const fourMonthsAgo = moment().subtract(4, 'months'); 
    const threeMonthsAgo = moment().subtract(3, 'months'); 
    const twoMonthsAgo = moment().subtract(2, 'months'); 
    const oneMonthsAgo = moment().subtract(1, 'months'); 
  
    
    const six = sixMonthsAgo.format('MMMM')
    const five = fiveMonthsAgo.format('MMMM')
    const four = fourMonthsAgo.format('MMMM')
    const three = threeMonthsAgo.format('MMMM')
    const two = twoMonthsAgo.format('MMMM')
    const one = oneMonthsAgo.format('MMMM')
    
  
    const data = {
      labels: [six,five,four,three,two,one],
      datasets: [
        {
          label: 'RICAVI',
          data: [this.state.totalesestomese, this.state.totalequintomese, this.state.totalequartomese, this.state.totaleterzomese, this.state.totalesecondomese, this.state.totaleprimomese],
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth:1,
        },
      ],
    };
    
    const {  isLoading  } = this.state;
    const windowWidth = Dimensions.get('window').width;
    return ( 
      <View>
        {isLoading ? (
          <View style={{flex: 1,justifyContent:'center'}}>
            <ActivityIndicator size="large" color="#333c5e" />
          </View>
        ) : (
          <Bar 
          width={windowWidth < 768 ? "10%" : "" }
          height={windowWidth < 768 ? "10%" : "" }
          data={data} />
        )}
      </View>
    )
  };
}