import * as React from 'react';
import {View, Text, TouchableOpacity, Image, ActivityIndicator, Alert, ScrollView, StyleSheet, TextInput, Dimensions} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Header from './../Header.js';
import Sidebar from './../components/Sidebar';

export default class  PuntoVendita extends React.Component { 
    constructor(props) {
        super(props);
        this.state = { 
            isLoading: true,
        }; 
    }
    componentDidMount=()=> {
        this.SearchPuntovendita();
    }

    SearchPuntovendita=async()=> {
        try {
            const storedValue = await AsyncStorage.getItem('puntovendita');
            this.setState({ nomePuntoVendita: storedValue });
            
        } catch (error) {
            Alert.alert("Error retrieving data" + error)
        }
        
        var puntovendita=this.state.nomePuntoVendita;
       
        var SearchAPIURL="https://gdoordini.it/superspesa/php/searchpuntovendita.php";
  
        var header={
            'Accept':'application/json',
            'Content-Type':'application/json'
        };
  
        var Data={
            puntovendita:puntovendita,

        };
  
        fetch(
            (SearchAPIURL),
            {
                method:'POST',
                headers:header,
                body: JSON.stringify(Data)
            }
        )
              
        .then((response)=>response.json())
        .then((response)=>
        {
            
            this.setState({indirizzo:response[0].indirizzo});
            this.setState({orari:response[0].orari});
            this.setState({telefono:response[0].telefono});
            this.setState({emailPuntoVendita:response[0].email});
            this.setState({cap:response[0].cap});
            this.setState({comune:response[0].comune});
            this.setState({provincia:response[0].provincia});
            this.setState({immagine:response[0].immagine});
            this.setState({ isLoading: false });
           
        })
        .catch((error)=>
        {
            alert(error)
        })
    }

    handleFirstNameChange = (nomePuntoVendita) => {
        this.setState({ nomePuntoVendita });
    };

    handleIndirizzoChange = (indirizzo) => {
        this.setState({ indirizzo });
    };

    handleCapChange = (cap) => {
        this.setState({ cap });
    };
    handleComuneChange = (comune) => {
        this.setState({ comune });
    };
    
    handleProvinciaChange = (provincia) => {
        this.setState({ provincia });
    };

    handleOrariChange = (orari) => {
        this.setState({ orari });
    };

    handleTelefonoChange = (telefono) => {
        this.setState({ telefono });
    };
    handleEmailChange = (emailPuntoVendita) => {
        this.setState({ emailPuntoVendita });
    };

    salvaPuntoVendita =async () => {

        var emailPuntoVendita=this.state.emailPuntoVendita;
        var checkemail=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    
        if (checkemail.test(emailPuntoVendita) === true) {

            var nomePuntoVendita=this.state.nomePuntoVendita;
            var indirizzo=this.state.indirizzo;    
            var cap=this.state.cap;
            var comune=this.state.comune;       
            var provincia=this.state.provincia;
            var orari=this.state.orari;
            var telefono=this.state.telefono;
            
            if (nomePuntoVendita.length == 0 || indirizzo.length == 0 || cap.length == 0 || comune.length == 0 || provincia.length == 0 || orari.length == 0 || telefono.length == 0 || emailPuntoVendita.length == 0)
            {
                alert("Inserire tutti i campi");
            }

            else
            {

                var SearchAPIURL="https://gdoordini.it/superspesa/php/salvapuntovendita.php";

                var header={
                    'Accept':'application/json',
                    'Content-Type':'application/json'
                };

                var Data={
                    nomePuntoVendita:nomePuntoVendita,
                    indirizzo:indirizzo,
                    cap:cap,
                    comune:comune,
                    provincia:provincia,
                    orari:orari,
                    telefono:telefono,
                    emailPuntoVendita:emailPuntoVendita,
                };
            
                fetch(
                    SearchAPIURL,
                    {
                        method:'POST',
                        headers:header,
                        body: JSON.stringify(Data)
                    }
                )
                .then((response)=>response.json())
                .then((response)=> {

                    alert('Successo', 'Modifiche salvate', [
                        {

                        },
                        {text: 'OK', },
                    ]);
                })
                .catch((error)=>
                    {
    
                        alert("Error4"+ error);
          
                    }
                )
            }
        } else {
        
            Alert.alert('Errore', 'Inserisci una email valida!');
        }
    };



    render() {

        const { nomePuntoVendita, indirizzo, orari, telefono, emailPuntoVendita, cap, comune, provincia, isLoading  } = this.state;
        const windowWidth = Dimensions.get('window').width;
        return (
            <View style={{backgroundColor:'#f4f7ff'}}>
                <View>
                    <Header />
                    <Sidebar>
                        <ScrollView contentContainerStyle={{flex:1,alignItems:'center'}}>
                            {isLoading ? (
                                <View style={{flex: 1,justifyContent:'center'}}>
                                    <ActivityIndicator size="large" color="#333c5e" />
                                </View>
                            ) : (
                                <View style={windowWidth < 768 ? styles.ViewStyleSmall : styles.ViewStyle}>
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/user.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>
                                            <TextInput
                                                placeholder="Nome*"
                                                value={this.state.nomePuntoVendita}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />
                                        </View>
                                    </View> 
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/maps.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>
                                            <TextInput
                                                placeholder="Indirizzo"
                                                value={this.state.indirizzo}
                                                onChangeText={this.handleIndirizzoChange}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />
                                        </View>
                                    </View>
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/mailbox.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>
                                            <TextInput
                                                placeholder="CAP"
                                                value={this.state.cap}
                                                onChangeText={this.handleCapChange}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />
                                        </View>
                                    </View>
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/city.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>
                                            <TextInput
                                                placeholder="Comune"
                                                value={this.state.comune}
                                                onChangeText={this.handleComuneChange}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />
                                        </View>
                                    </View>                                    
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/city.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>
                                            <TextInput
                                                placeholder="Provincia"
                                                value={this.state.provincia}
                                                onChangeText={this.handleProvinciaChange}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />
                                        </View>
                                    </View>
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/time.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>
                                            <TextInput
                                                placeholder="Orari"
                                                value={this.state.orari}
                                                onChangeText={this.handleOrariChange}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />   
                                        </View>
                                    </View>
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/phone-call.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>                                                        
                                            <TextInput
                                                placeholder="Telefono"
                                                value={this.state.telefono}
                                                onChangeText={this.handleTelefonoChange}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />  
                                        </View>
                                    </View>
                                    <View style={{flexDirection:'row',flex:1,marginLeft:10,marginTop:10}}>
                                        <View style={windowWidth < 768 ? styles.VievImageStyleSmall: styles.VievImageStyle}>
                                            <Image 
                                                style={windowWidth < 768 ? styles.ImageStyleSmall: styles.ImageStyle}
                                                source={require('./../images/email.png')}
                                                resizeMode="contain" 
                                            />
                                        </View>
                                        <View style={{justifyContent:'center',flex:1.5,marginRight:10}}>
                                            <TextInput
                                                placeholder="Email"
                                                value={this.state.emailPuntoVendita}
                                                onChangeText={this.handleEmailChange}
                                                style={windowWidth < 768 ? styles.TextInputStyleClassSmall: styles.TextInputStyleClass}
                                                onFocus={(e) => {
                                                    e.target.style.outline = 'none'; 
                                                }}
                                            />   
                                        </View>                                    
                                    </View>
                                    <View style={{marginBottom:10,marginTop:50,marginLeft:10,marginRight:10,alignItems:'center'}}>
                                        <TouchableOpacity style={styles.RegistrazioneStyle} onPress={this.salvaPuntoVendita}>
                                            <Text style={{ color:'white', fontFamily:'LilitaOne-Regular'}}>SALVA</Text>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                            )
                            }
                        </ScrollView>
                    </Sidebar >
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    TextInputStyleClass: {
        fontSize:20,
        marginBottom: 5,
        height: 50,
        borderBottomWidth: 1,
        borderColor: 'grey',
        fontFamily:'OpenSans-Regular',
        
    },
    TextInputStyleClassSmall: {
        fontSize:15,
        marginBottom: 5,
        height: 50,
        borderBottomWidth: 1,
        borderColor: 'grey',
        fontFamily:'OpenSans-Regular',
        
    },    
    RegistrazioneStyle :{
        width:'25%',
        height:50,
        backgroundColor:'#333c5e',
        alignItems:'center',
        justifyContent:'center',
        borderRadius:10,
        marginBottom:5
    },
    ViewStyle: {
        marginLeft:10, 
        marginRight:10, 
        marginTop:10, 
        backgroundColor:'white', 
        borderRadius:10,
        width:500
    },
    ViewStyleSmall: {
        marginLeft:10, 
        marginRight:10, 
        marginTop:10, 
        backgroundColor:'white', 
        borderRadius:10,
        width:'90%'
    },
    ImageStyle: {
        width: 50, 
        height: 50
    },
    ImageStyleSmall: {
        width: 25, 
        height: 25
    },
    VievImageStyle: {
        marginRight:10,
        justifyContent:'center',
        flex:0.5
    },
    VievImageStyleSmall: {
        marginRight:10,
        justifyContent:'center',
        flex:0.25
    },       
});