import * as React from 'react'; 
import {Text, View, StyleSheet, FlatList, ActivityIndicator, Alert, TouchableOpacity, Image, TextInput, Dimensions } from 'react-native';
import Sidebar from './../components/Sidebar';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Header from './../Header.js';



export default class VediOrdine extends React.Component { 
    constructor(props) {
        super(props);
        this.state = { 
            search: '', 
            isLoading: false,
            isSearch: true,
            FindEan:'',
            numero:20
        }; 
       
    }
    
    componentDidMount = async () => {
        this.onLoad();
        this.TotalRecord();
        this.TotalSpesa(); 
    }

    handleClick = async() => {
        this.setState(prevState => ({
            numero: prevState.numero + 20,
        }));    
        this.setState({isLoading: true});
        this.onLoad();
    };

    onLoad = async () => {
        try {
            const storedValue = await AsyncStorage.getItem('puntovendita');
            this.setState({ puntovendita: storedValue }); 
            const storedValue2 = await AsyncStorage.getItem('id');
            this.setState({ id: storedValue2 });  


           
               
        } catch (error) {
            alert("Error retrieving data" + error)
        }
        
        var puntovendita = this.state.puntovendita;  
        
        var id = window.location.href.split("id=")[1];
        
        var getidordine = window.location.href.split("idordine=")[1];
        var idordine = getidordine.slice(0, 13);
        
        
        var SearchAPIURL="https://gdoordini.it/superspesa/php/vedoordinestoricospesaweb.php";
    
        var header={
          'Accept':'application/json',
          'Content-Type':'application/json'
        };
        
        var Data={
            puntovendita:puntovendita,
            id:id,
            idordine:idordine
        };
        fetch(
          (SearchAPIURL),
          {
            method:'POST',
            headers:header,
            body: JSON.stringify(Data)
          }
        )
        .then(response => response.json())
        .then(responseJson => { 
           
            
            this.setState(
                {
                    isLoading: false, dataSource: responseJson,
                },
                function() {
                    this.arrayholder = responseJson;
                }
            );
        })
        .catch(error => { 
            alert('errore vedoordinestoricospesa:' + error)});
    }

    handlesearchTextChange = (searchText) => {
        this.setState({ searchText });
    };
    
    handleSearch = () => {
        this.setState({isSearch: false});  
        this.setState({numero: 20}); 
        var searchText = this.state.searchText;
        
       if (searchText != undefined && searchText.length > 0 ) {

            var puntovendita = this.state.puntovendita; 
            var id = window.location.href.split("id=")[1];
        
            var getidordine = window.location.href.split("idordine=")[1];
            var idordine = getidordine.slice(0, 13); 
        
            var SearchAPIURL="https://gdoordini.it/superspesa/php/cercaarticolostoricospesaweb.php";
    
            var header={
                'Accept':'application/json',
                'Content-Type':'application/json'
            };
        
            var Data={
                puntovendita:puntovendita,
                searchText:searchText,
                id:id,
                idordine:idordine
            
            };
            fetch(
                (SearchAPIURL),
                {
                    method:'POST',
                    headers:header,
                    body: JSON.stringify(Data)
                }
            )
            .then(response => response.json())
            .then(responseJson => { 
           
                this.setState(
                    {
                        isLoading: false, dataSource: responseJson,
                    },
                );
            })
            .catch(error => { alert(error);});
        } else {
            this.onLoad();
        }
    };    

    TotalRecord = async () => {
        try {
            const storedValue = await AsyncStorage.getItem('puntovendita');
            this.setState({ puntovendita: storedValue });
            const storedValue2 = await AsyncStorage.getItem('id');
            this.setState({ id: storedValue2 });         
        } catch (error) {
            Alert.alert("Error retrieving data" + error)
        }
        
        var totalequantita=this.state.totalequantita;
        var puntovendita = this.state.puntovendita; 
        var id = window.location.href.split("id=")[1];   
        var getidordine = window.location.href.split("idordine=")[1];
        var idordine = getidordine.slice(0, 13); 
        var SearchAPIURL="https://gdoordini.it/superspesa/php/totalestoricoquantitaspesa.php";
  
        var header={
            'Accept':'application/json',
            'Content-Type':'application/json'
        };
  
        var Data={
            totalequantita:totalequantita,
            puntovendita:puntovendita,
            id:id,
            idordine:idordine
        };
  
        fetch(
            (SearchAPIURL),
            {
                method:'POST',
                headers:header,
                body: JSON.stringify(Data)
            }
        )
        .then((response)=>response.json())
        .then((response)=>
            {
                var controlquantita = response[0].totalequantita;
                if (controlquantita === null) {
                    this.setState({totalequantita:'0'});
                }
                else {
                
                  this.setState({totalequantita:response[0].totalequantita});
       
                }  
            }
        )
        .catch((error)=>
            {
               alert('errore totalestoricoquantitaspesa:' + error)   
            }
        )
    }

    TotalSpesa = async () => {
        try {
            const storedValue = await AsyncStorage.getItem('puntovendita');
            this.setState({ puntovendita: storedValue });   
            const storedValue2 = await AsyncStorage.getItem('id');
            this.setState({ id: storedValue2 });     
        } catch (error) {
            Alert.alert("Error retrieving data" + error)
        }
          
        var totalestoricospesa=this.state.totalestoricospesa;
        var puntovendita = this.state.puntovendita;   
        var id = window.location.href.split("id=")[1];   
        var getidordine = window.location.href.split("idordine=")[1];
        var idordine = getidordine.slice(0, 13); 
        var SearchAPIURL="https://gdoordini.it/superspesa/php/totalestoricospesasingola.php";
    
        var header={
          'Accept':'application/json',
          'Content-Type':'application/json'
        };
    
        var Data={
            totalestoricospesa:totalestoricospesa,
            puntovendita:puntovendita,
            id:id,
            idordine:idordine
        };
        fetch(
            (SearchAPIURL),
            {
                method:'POST',
                headers:header,
                body: JSON.stringify(Data)
            }
        )
        .then((response)=>response.json())
        .then((response)=>
            {
                var controlspesa = response[0].totalestoricospesa;
                    
                if (controlspesa === null) {
                    this.setState({totalestoricospesa:'0'});
                }
                else {
                    this.setState({totalestoricospesa:response[0].totalestoricospesa});
                }  
            }
        )
        .catch((error)=>
            {
              alert('errore totalestoricospesasingola:' + error)      
            }
        )
    }      
      
    render() {
        
        const Separator = () => (
            <View style={styles.separator} />
        );
      
        const { isLoading, searchText, isSearch  } = this.state;
        const windowWidth = Dimensions.get('window').width;
        return (
            <View style={{backgroundColor:'#f4f7ff'}}>
                <Header isCart={true}/>
                <Sidebar>
                    <View style={{alignItems:'center',marginLeft:10,marginRight:10}}>
                        {isLoading ? (
                            <View style={{flex: 1,justifyContent:'center'}}>
                                <ActivityIndicator size="large" color="#333c5e" />
                            </View>
                        ) : (
                            <View style={windowWidth < 768 ? styles.viewStyleSmall: styles.viewStyle}>
                                <View style={{flexDirection:'row'}}> 
                                    <View style={{flex:0.1,justifyContent:'center', alignItems:'center',borderLeftWidth:1,borderTopWidth:1,borderBottomWidth:1,borderColor:'grey',borderTopLeftRadius:10,borderBottomLeftRadius:10}}> 
                                        <TouchableOpacity onPress={this.handleSearch}>
                                            <Image 
                                                style={styles.Image}
                                                source={require('./../images/Ricerca.png')}
                                                resizeMode="contain" 
                                            />
                                        </TouchableOpacity>  
                                    </View>
                                    <View style={{flex:0.9}}>    
                                        <TextInput
                                            style={{ height: 50,   borderTopRightRadius:10,borderBottomRightRadius:10,borderRightWidth:1,borderTopWidth:1,borderBottomWidth:1,borderColor:'grey' }}
                                            placeholder="Cerca..."
                                            onChangeText={this.handlesearchTextChange}
                                            value={this.state.searchText}
                                            onFocus={(e) => {
                                                e.target.style.outline = 'none'; 
                                            }}
                                        />
                                    </View>
                                </View>            
                                <Separator />
                                <View style={{flexDirection:'row',marginLeft:5,marginRight:5, marginTop:10, marginBottom:10}}>
                        <View style={{flex:0.5,alignItems:'center'}}>   
                            <Text style={windowWidth < 768 ? styles.TitleSubSmall: styles.TitleSub}>TOTALE SPESA</Text>
                            <Text style={{color:'black', fontWeight:'bold',marginTop:10, fontSize:15}}>{this.state.totalestoricospesa} €</Text>
                        </View>
                        <View style={{flex:0.5,alignItems:'center'}}>  
                            <Text style={windowWidth < 768 ? styles.TitleSubSmall: styles.TitleSub}>TOTALE QUANTITA'</Text>
                            <Text style={{color:'black', fontWeight:'bold',marginTop:10, fontSize:15}}>{this.state.totalequantita}</Text>
                        </View>
                    </View>
                                <View style={styles.ViewSub}>
                                    <View style={styles.ViewTitleCodice}>
                                        <Text style={windowWidth < 768 ? styles.TitleSubSmall: styles.TitleSub}>EAN</Text>
                                    </View>
                                    <View style={styles.ViewTitleCodice}>
                                        <Text style={windowWidth < 768 ? styles.TitleSubSmall: styles.TitleSub}>DESCRIZIONE</Text>
                                    </View>  
                                    <View style={styles.ViewTitleCodice}>
                                        <Text style={windowWidth < 768 ? styles.TitleSubSmall: styles.TitleSub}>QUANTITA</Text>
                                    </View> 
                                    <View style={styles.ViewTitleCodice}>
                                        <Text style={windowWidth < 768 ? styles.TitleSubSmall: styles.TitleSub}>PREZZO</Text>
                                    </View> 
                                    <View style={styles.ViewTitleCodice}>
                                        <Text style={windowWidth < 768 ? styles.TitleSubSmall: styles.TitleSub}>TOTALE</Text>
                                    </View> 
                                                                                                    
                                </View>
                                <FlatList 
                                    data={this.state.dataSource}
                                    renderItem={({ item }) => (
                                        <View style={windowWidth < 768 ? styles.viewStyleSmall: styles.viewStyle}>
                                            <View style={styles.ViewSub}>
                                                <View style={styles.ViewTitleCodice}>
                                                    <Text style={windowWidth < 768 ? styles.TitleSub2Small: styles.TitleSub2}>{item.ean}</Text>
                                                </View>
                                                <View style={styles.ViewTitleCodice}>
                                                    <Text style={windowWidth < 768 ? styles.TitleSub2Small: styles.TitleSub2}>{item.descrizione}</Text>
                                                </View> 
                                                <View style={styles.ViewTitleCodice}>
                                                    <Text style={windowWidth < 768 ? styles.TitleSub2Small: styles.TitleSub2}>{item.quantita}</Text>
                                                </View> 
                                                <View style={styles.ViewTitleCodice}>
                                                    <Text style={windowWidth < 768 ? styles.TitleSub2Small: styles.TitleSub2}>{item.prezzo}</Text>
                                                </View>    
                                                <View style={styles.ViewTitleCodice}>
                                                    <Text style={windowWidth < 768 ? styles.TitleSub2Small: styles.TitleSub2}>{item.totale}</Text>
                                                </View>                                     

                                      
                                            </View>                                       
                                        </View>
                                    )}
                                    enableEmptySections={true} 
                                    style={{ marginTop: 11 }}
                                    keyExtractor={(item, index) => index.toString()}
                                />
                            </View>
                        )}
                        {isSearch ? (
                            <TouchableOpacity style={{marginTop:10,marginBottom:10}} onPress={this.handleClick}>
                                <Text style={{fontWeight:'bold',color:'#333c5e'}}>MOSTRA DI PIU'</Text>
                            </TouchableOpacity>
                        ) : (
                            <View></View>
                        )}
                    </View> 
                </Sidebar>
            </View>
        );
    }
}

const styles = StyleSheet.create({ 
    viewStyle: {
        justifyContent: 'center', 
        backgroundColor:'white',
        width:1000,
        borderRadius:10,
    },
    viewStyleSmall: {
        justifyContent: 'center', 
        backgroundColor:'white',
        width:'100%',
        borderRadius:10
    },   

    ViewTitleCodice: {
        flex:0.4, 
       height:50,
       justifyContent:'center'
    },
    ViewTitleCodice2: {
        flex:0.35, 
        height:50,
        justifyContent:'center'
    },
    
    ViewSub: {
        flexDirection:'row', 
        justifyContent:'center',
        alignItems:'center'
    },
    TitleSub:{
        fontSize:15, 
        fontWeight:'bold',
        fontFamily:'OpenSans-Regular',
        textAlign:'center',
        color:'#333c5e'
    },
    TitleSubSmall:{
        fontSize:9, 
        fontWeight:'bold',
        fontFamily:'OpenSans-Regular',
        textAlign:'center',
        color:'#333c5e'
    },
    TitleSub2:{
        fontSize:15, 
        color:'grey',
        fontFamily:'OpenSans-Regular',
        textAlign:'center'
    },
    TitleSub2Small:{
        fontSize:7, 
        color:'grey',
        fontFamily:'OpenSans-Regular',
        textAlign:'center'
    },

    Image: {
        width: 25, 
        height: 25
    },
    separator: {
       height: 10,
        backgroundColor:'#f4f7ff'
    },
});