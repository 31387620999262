import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import HeroSection from './pages//HeroSection';
import Dashboard from './pages/Dashboard.jsx';
import PuntoVendita from './pages/PuntoVendita.js';
import Clienti from './pages/Clienti.js';
import ModificaCliente from './pages/ModificaCliente.js';
import ProdottiList from './pages/ProdottiList.js';
import Profilo from './pages/Profilo.js';
import Ordini from './pages/Ordini.js';
import VediOrdine from './pages/VediOrdine.js';
import Privacy from './pages/Privacy.js';
import Paypal from './pages/Paypal.js';

import Footer from './components/Footer';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HeroSection />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/PuntoVendita" element={<PuntoVendita />} />
        <Route path="/Clienti" element={<Clienti />} />
        <Route path="/ModificaCliente" element={<ModificaCliente />} />
        <Route path="/prodottiList" element={<ProdottiList />} />
        <Route path="/Profilo" element={<Profilo />} />
        <Route path="/Ordini" element={<Ordini />} />
        <Route path="/VediOrdine" element={<VediOrdine />} />
        <Route path="/Privacy" element={<Privacy />} />
        <Route path="/Paypal" element={<Paypal />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;