import * as React from 'react';
import {View, Text, TouchableOpacity, Image, Dimensions, ActivityIndicator, StyleSheet} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';

export default class  AggiornaClienti extends React.Component { 
    constructor(props) {
      super(props);
      this.state = { 
        isLoading: true,
      }; 
    }

    UpdateArticoli = () => {
        alert('ciao')
    }

    render() {
        const windowWidth = Dimensions.get('window').width;
        return (
            <View style={windowWidth < 768 ? styles.ViewStyleSmall : styles.ViewStyle}>
                <TouchableOpacity onPress={this.UpdateArticoli} style={windowWidth < 768 ? styles.ButtonStyleSmall : styles.ButtonStyle}>
                    <Text style={{ color:'white', fontFamily:'LilitaOne-Regular', fontWeight:'bold', textAlign:'center'}}>AGGIORNA ARTICOLI</Text>
                </TouchableOpacity>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    ViewStyle: {
        marginBottom:10,
        marginLeft:10,
        marginRight:10,
        alignItems:'flex-end',
        flex:0.5
    },
    ViewStyleSmall: {
        marginBottom:10,
        marginLeft:10,
        marginRight:10,
        alignItems:'center',
        flex:0.5
    },
    ButtonStyle: {
        width:'25%',
        height:50, 
        backgroundColor:'#333c5e',
        alignItems:'center', 
        justifyContent:'center', 
        borderRadius:10, 
        marginBottom:5
    },
    ButtonStyleSmall: {
        width:'90%',
        height:50, 
        backgroundColor:'#333c5e',
        alignItems:'center', 
        justifyContent:'center', 
        borderRadius:10, 
        marginBottom:5
    }
})